import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Posts from "../components/Posts"
import RightBar from "../components/RightBar";
import Sidebar from "../components/Sidebar";
import Container from "react-bootstrap/Container"

function AllPosts() {
    return (
        <Row>
            <Col xs="auto" className="m-0 p-0">
                <Sidebar posts={true}/>
            </Col>
            <Col className="m-0 p-0 mx-lg-5 mt-3">
                <Container>
                    <Row>
                        <Col>
                            <Posts/>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <RightBar/>
        </Row>
    );
}

export default AllPosts;