const Loader = (props: { isLoading: boolean, component: any, error: string, message: string }) => {
    const Component = props.component;

    if (props.error) {
        return (
            <div className="bg-danger-subtle loader">
                {props.error}
            </div>
        )
    }

    if (props.message) {
        return (
            <div className="bg-info-subtle loader">
                {props.message}
            </div>
        )
    }

    if (props.isLoading) {
        return (
            <div className="p-3 text-center">
                <i className="fa fa-spinner fa-spin fa-xl"></i>
            </div>
        )
    }

    return (
        <>
            <Component/>
        </>
    )
};

export default Loader;