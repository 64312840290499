import React, {useEffect} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Posts from "../components/Posts"
import {useParams} from "react-router-dom";
import RightBar from "../components/RightBar";
import Sidebar from "../components/Sidebar";
import TradingViewMiniChart from "../components/TradingViewMiniChart";
import Container from "react-bootstrap/Container"

function Ticker() {
    const {ticker} = useParams();
    useEffect(() => {
        document.title = `CoinTool - ${ticker} to USD`
    }, [ticker])
    return (
        <Row>
            <Col xs="auto" className="m-0 p-0">
                <Sidebar posts={true}/>
            </Col>
            <Col className="m-0 p-0 mx-lg-5 mt-3">
                <Container>
                    <Row>
                        <Col>
                            <TradingViewMiniChart ticker={ticker}/>
                            <Posts/>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <RightBar/>
        </Row>
    );
}

export default Ticker;