import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'font-awesome/css/font-awesome.min.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./routes/Home";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Login from "./routes/Login";
import Terms from "./routes/Terms";
import Privacy from "./routes/Privacy";
import Cookies from "./routes/Cookies";
import Disclaimer from "./routes/Disclaimer";
import SignUp from "./routes/SignUp";
import ResetPassword from "./routes/ResetPassword";
import Ticker from "./routes/Ticker"
import UserSettings from "./routes/UserSettings";
import Action from "./routes/Action";
import AllPosts from "./routes/AllPosts";
import 'react-toggle/style.css'


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout displaySidebar={true}/>}>
                    <Route index element={<Home/>}/>
                </Route>
                <Route path="/symbol/:ticker" element={<Layout displaySidebar={true}/>}>
                    <Route index element={<Ticker/>}/>
                </Route>
                <Route path="/posts" element={<Layout displaySidebar={true}/>}>
                    <Route index element={<AllPosts/>}/>
                </Route>
                <Route path="/login" element={<Layout displaySidebar={false}/>}>
                    <Route index element={<Login/>}/>
                </Route>
                <Route path="/sign-up" element={<Layout displaySidebar={false}/>}>
                    <Route index element={<SignUp/>}/>
                </Route>
                <Route path="/action" element={<Layout displaySidebar={false}/>}>
                    <Route index element={<Action/>}/>
                </Route>
                <Route path="/reset-password" element={<Layout displaySidebar={false}/>}>
                    <Route index element={<ResetPassword/>}/>
                </Route>
                <Route path="/terms-of-use" element={<Layout displaySidebar={true}/>}>
                    <Route index element={<Terms/>}/>
                </Route>
                <Route path="/privacy-policy" element={<Layout displaySidebar={true}/>}>
                    <Route index element={<Privacy/>}/>
                </Route>
                <Route path="/cookie-policy" element={<Layout displaySidebar={true}/>}>
                    <Route index element={<Cookies/>}/>
                </Route>
                <Route path="/disclaimer" element={<Layout displaySidebar={true}/>}>
                    <Route index element={<Disclaimer/>}/>
                </Route>
                <Route path="/user/settings" element={<Layout displaySidebar={true}/>}>
                    <Route index element={<UserSettings/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);