import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import { useLocation } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import fetchData from "../utils/Fetch";

function TickerTape() {
    const [titles, setTitles] = useState([]);
    const location = useLocation();
    const display = !['/login', '/sign-up', '/action', '/reset-password'].includes(location.pathname)

    useEffect(() => {
        fetchData(`/api/titles`).then((response) => {
            if (response) {
                setTitles(response.titles)
            }
        })
    }, [])

    return (
        <Container className={`m-0 px-0 py-3 ticker-tape text-center small ${display ? '' : 'd-none'}`}>
                    <Marquee>
                        {titles.map((title: any) => {
                            return (
                                <div key={title.id}>
                                    <a href="/posts?type=news" className="ticker-tape-link" style={{
                                        fontSize: '12px',
                                        textDecoration: "none",
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                    }}>{title.title}</a><b className="mx-2 px-1" style={{color: "#000000"}}>&bull;</b>
                                </div>
                            );
                        })}
                    </Marquee>
        </Container>
)
}

export default TickerTape;