import React, {useState} from "react";
import {auth, googleProvider} from "../config/firebase";
import {
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import Message from "./Message";
import {Link} from "react-router-dom";

function LoginForm() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const navigate = useNavigate();

    const signIn = async (e: any) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/");
        } catch (e: any) {
            setMessage(e.message.replace("Firebase: ", ""))
            setMessageClass("text-danger")
        }
    }

    const signInWithGoogle = async (e: any) => {
        e.preventDefault();
        try {
            await signInWithPopup(auth, googleProvider);
            navigate("/");
        } catch (e: any) {
            setMessage(e.message.replace("Firebase: ", ""))
            setMessageClass("text-danger")
        }
    }

    return (
        <div>
            <Link to="/sign-up">
                <button type="submit" className="btn btn-primary w-100 sign-up-button numeric-dark-blue">
                    Sign Up
                </button>
            </Link>
            <button className="btn btn-primary w-100 box-up-button mt-3"
                    onClick={(e) => signInWithGoogle(e)}>
                <FontAwesomeIcon icon={faGoogle} className="fa-sm mx-2"/>Log In with Google
            </button>
            <form onSubmit={signIn} className="rounded-3 p-3 mt-3 auth-form">
                <div className="form-group p-2">
                    <label className="font-weight-bold">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
                <div className="form-group p-2">
                    <label>Password</label><Link to="/reset-password"><label
                    className="small float-end text-secondary">Forgot password</label></Link>
                    <input
                        type="password"
                        className="form-control"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <div className="form-group p-2">
                    <button type="submit" className="btn btn-primary w-100 mt-2 log-in-button">
                        Log In
                    </button>
                </div>
                <div className="form-group m-2">
                    <Message message={message} class={messageClass}/>
                </div>
            </form>
        </div>
    );
}

export default LoginForm;