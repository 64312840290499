import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Sidebar from "../components/Sidebar"
import Tickers from "../components/Tickers"
import RightBar from "../components/RightBar"
import Container from "react-bootstrap/Container"

function Home() {
    return (
        <Row>
            <Col xs="auto" className="m-0 p-0">
                <Sidebar/>
            </Col>
            <Col className="m-0 p-0 mx-lg-5">
                <Container>
                    <Row>
                        <Col>
                            <Tickers/>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <RightBar/>
        </Row>
    );
}

export default Home;