import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {GoogleAuthProvider} from "firebase/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD1udyxWRv7cHMZ21MlrGJj1RWZa7renhs",
    authDomain: "numeric-net.firebaseapp.com",
    projectId: "numeric-net",
    storageBucket: "numeric-net.appspot.com",
    messagingSenderId: "826044589051",
    appId: "1:826044589051:web:e5b9f940876a3616dbe15e",
    measurementId: "G-CKKZBSJQQB"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider();
export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider('6Lc6TOIpAAAAAHNEkod7__sJWUBKVbzZVea0K5qa'),
    isTokenAutoRefreshEnabled: true
});
export const db = getFirestore(app);