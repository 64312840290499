import React, { useEffect, useRef } from 'react';

const TradingViewMiniChart = (props: { ticker?: any }) => {
    const [isDarkMode, setIsDarkMode] = React.useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode === null ? false : savedMode === 'true';
    });

    useEffect(() => {
        const updateMode = () => {
            setTimeout(() => {
                const savedMode = localStorage.getItem('darkMode');
                if (savedMode) {
                    setIsDarkMode(savedMode === 'true')
                }
            }, 0)
        }
        window.addEventListener('darkModeUpdated', updateMode);
        return () => {
            window.removeEventListener('darkModeUpdated', updateMode);
        };
    }, []);

    const getScript = () => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
        script.async = true;
        script.innerHTML = JSON.stringify(  {
            "symbol": "CRYPTO:" + props.ticker + "USD",
            "width": "100%",
            "height": "100%",
            "locale": "en",
            "dateRange": "1D",
            "colorTheme": isDarkMode ? "dark" : "light",
            "trendLineColor": "rgba(73, 133, 231, 1)",
            "underLineColor": "rgba(73, 133, 231, 1)",
            "underLineBottomColor": "rgba(73, 133, 231, 0)",
            "isTransparent": false,
            "autosize": true,
            "largeChartUrl": "",
            "noTimeScale": true
        });
        return script
    }
    const script = getScript()

    const widgetRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const current = widgetRef.current
        if (!current || current.firstChild) {
            return
        }
        current.appendChild(script);
        return () => {
            try {
                while (current.firstChild) {
                    current.removeChild(current.firstChild);
                }
            } catch (e) {
                return
            }
        }
    }, [widgetRef, props.ticker, isDarkMode, script]);

    return (
        <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget" ref={widgetRef}></div>
            <div className="tradingview-widget-copyright">
                <a href="https://www.tradingview.com/" rel="noopener nofollow noreferrer" target="_blank">
                    <span className="numeric-link">Track all markets on TradingView</span>
                </a>
            </div>
        </div>
    );
};

export default TradingViewMiniChart;