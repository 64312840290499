import React from "react";

function RightBar() {
    return (
        <div
            className="col-2 text-center d-none d-lg-block m-0 p-0 text-uppercase">
        </div>
    )
}

export default RightBar;