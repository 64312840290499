import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

const GoogleNews = (post: any) => {
    return (
        <div className="py-2 px-3 rounded-1 post-container">
            <Row className="only-mobile">
                {post.image ? (<Col>
                    <img src={post.image} className="post-image w-100 my-2 rounded-1" alt=""/>
                </Col>) : ''}
            </Row>
            <Row className="my-1">
                {post.image ? (<Col className="col-3 d-none d-md-block">
                    <img src={post.image} className="post-image w-100 my-2 rounded-1" alt=""/>
                </Col>) : ''}
                <Col>
                    <h5><b className="post-date float-md-end my-2 mx-md-1">{post.date}</b> <a href={post.url}
                                                                                                    target="_blank"
                                                                                                    className="numeric-link mx-1 mx-md-0 fs-6"
                                                                                                    rel="noreferrer">{post.title.length > 100 ? post.title.substring(0, 100) + '...' : post.title}</a>
                    </h5>
                    <b className="post-source"> {post.source}</b>
                    <br/>
                    <span
                        className="post-description d-inline">{post.description && post.description.length > 150 ? post.description.substring(0, 150) + '...' : post.description}</span>
                    <ul className={`ticker-tags ${post.tickers.length === 0 ? 'd-none' : ''}`}>
                        {post.tickers.map((ticker: any) => (
                            <li key={ticker} onClick={() => {
                                window.location.href = `/symbol/${ticker}?type=news`
                            }}>
                                {ticker}
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

const X = (post: any) => {
    return (
        <div className="py-2 px-3 rounded-1 post-container">
            <Row className="only-mobile">
                {post.image ? (<Col>
                    <img src="/x.webp" className="post-image w-100 my-2 rounded-1" alt=""/>
                </Col>) : ''}
            </Row>
            <Row className="my-1">
                {post.image ? (<Col className="col-3 d-none d-md-block">
                    <img src="/x.webp" className="post-image w-100 my-2 rounded-1" alt=""/>
                </Col>) : ''}
                <Col>
                    <h5><b className="post-date float-md-end my-2 mx-md-1">{post.date}</b> <a href={post.url}
                                                                                              target="_blank"
                                                                                              className="numeric-link mx-1 mx-md-0 fs-6"
                                                                                              rel="noreferrer">{post.source.charAt(0).toUpperCase() + post.source.slice(1)}</a>
                    </h5>
                    <b className="post-source"> X / Twitter</b>
                    <br/>
                    <span
                        className="post-description d-inline">{post.description && post.description.length > 150 ? post.description.substring(0, 150) + '...' : post.description}</span>
                    <ul className={`ticker-tags ${post.tickers.length === 0 ? 'd-none' : ''}`}>
                        {post.tickers.map((ticker: any) => (
                            <li key={ticker} onClick={() => {
                                window.location.href = `/symbol/${ticker}?type=news`
                            }}>
                                {ticker}
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

const Post = (props: { post: any }) => {
    if (props.post.vendor === "GoogleNews") {
        return GoogleNews(props.post)
    }
    if (props.post.vendor === "X") {
        return X(props.post)
    }
    return <></>
};

export default Post;