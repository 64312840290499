import {NavLink} from "react-router-dom";
import {Sidebar as ProSideBar, Menu, MenuItem} from 'react-pro-sidebar';
import {faUserCog, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {signOut} from "firebase/auth";
import {auth} from "../config/firebase";
import ResponsiveIcon from "./ResponsiveIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

function UserSidebar() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const className = isMobile ? "" : "my-3 menu-item-border"
    const logOut = async () => {
        await signOut(auth)
    }
    return (
        <div className="d-flex d-none d-lg-block mx-0 p-0 sidebar" id="sidebar">
            <ProSideBar>
                <Menu>
                    <MenuItem component={<NavLink to="/user/settings" />} icon={<ResponsiveIcon icon={faUserCog}/>} className={className}>Settings</MenuItem>
                    <MenuItem onClick={(e) => logOut()} icon={<ResponsiveIcon icon={faUserCircle}/>} className={className}>Log Out</MenuItem>
                </Menu>
            </ProSideBar>
        </div>
    );
}

export default UserSidebar;