import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import { Sidebar as ProSideBar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faChrome } from '@fortawesome/free-brands-svg-icons'
import React, {useEffect, useState} from "react";
import Toggle from "react-toggle";
import ResponsiveIcon from "./ResponsiveIcon";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Sidebar(props: {posts?: boolean}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [postType, setPostType] = React.useState('all');
    const [searchParams] = useSearchParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [columns, setColumns] = useState<Map<string, boolean>>(new Map([
        ['bookmark', true],
        ['volume', !isMobile],
        ['marketCap', true],
    ]));

    useEffect(() => {
        const storedColumns = localStorage.getItem('columns');
        if (storedColumns) {
            setColumns(new Map(JSON.parse(storedColumns)));
        }
    }, []);

    const setColumn = (key: string) => {
        setColumns((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(key, !prevMap.get(key)); // Toggle boolean value
            localStorage.setItem('columns', JSON.stringify(Array.from(newMap.entries())));
            return newMap;
        });
        window.dispatchEvent(new Event("updatedColumns"));
    };

    const handlePostTypeChange = (m: string) => {
        setPostType(m)
        setQueryParameter([{key: 'type', value: m}]);
    };

    const loadParams = () => {
        if (searchParams) {
            const type = searchParams.get("type")
            if (type) {
                setPostType(type)
            } else {
                setPostType('all')
            }
        }
    }

    const setQueryParameter = (params: {key: string, value: string}[]) => {
        const searchParams = new URLSearchParams(location.search);
        for (let i = 0; i < params.length; i++) {
            searchParams.set(params[i].key, params[i].value);
        }
        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadParams, [searchParams])

    return props.posts ? (
        <div className="d-flex d-none d-lg-block mx-0 p-0 sidebar" id="sidebar">
            <ProSideBar>
                <Menu>
                    <SubMenu label="Platform" defaultOpen={true} icon={<ResponsiveIcon icon={faChrome}/>}>
                        <MenuItem component={<b onClick={() => handlePostTypeChange('all')}/>} className="submenu-item">All<Toggle onChange={() => handlePostTypeChange('all')} checked={postType === 'all'} icons={false} className="float-end custom-toggle"/></MenuItem>
                        <MenuItem component={<b onClick={() => handlePostTypeChange('news')}/>} className="submenu-item">News<Toggle onChange={() => handlePostTypeChange('news')} checked={postType === 'news'} icons={false} className="float-end custom-toggle"/></MenuItem>
                        <MenuItem component={<b onClick={() => handlePostTypeChange('social')}/>} className="submenu-item">Social<Toggle onChange={() => handlePostTypeChange('social')} checked={postType === 'social'} icons={false} className="float-end custom-toggle"/></MenuItem>
                    </SubMenu>
                </Menu>
            </ProSideBar>
        </div>
        ) : (
        <div className="d-flex d-none d-lg-block mx-0 p-0 sidebar" id="sidebar">
            <ProSideBar>
                <Menu>
                    <SubMenu label="Display" defaultOpen={true} icon={<ResponsiveIcon icon={faGlobe}/>}>
                        <MenuItem component={<b onClick={() => setColumn('volume')}/>} className="submenu-item">Volume<Toggle onChange={() => setColumn('volume')} checked={columns.get('volume')} icons={false} className="float-end custom-toggle"/></MenuItem>
                        <MenuItem component={<b onClick={() => setColumn('marketCap')}/>} className="submenu-item">Cap<Toggle onChange={() => setColumn('marketCap')} checked={columns.get('marketCap')} icons={false} className="float-end custom-toggle"/></MenuItem>
                        <MenuItem component={<b onClick={() => setColumn('bookmark')}/>} className="submenu-item">Bookmark<Toggle onChange={() => setColumn('bookmark')} checked={columns.get('bookmark')} icons={false} className="float-end custom-toggle"/></MenuItem>
                    </SubMenu>
                </Menu>
            </ProSideBar>
        </div>
    )
}

export default Sidebar;